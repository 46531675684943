import { Icon, Link, Separator } from '@fluentui/react'
import { ReactNode, useCallback, useMemo, useState } from 'react'
import { createUseStyles } from 'react-jss'

import { colors } from '@publica/ui-common-styles'
import { FC } from '@publica/ui-common-utils'
import { insertBetweenElements } from '@publica/utils'

import { MainFrame } from '../MainFrame'

export type SectionDescriptor = {
    title?: string
    content: ReactNode
    openByDefault?: boolean
    showTitle?: boolean
}

type SectionFrameProps = {
    sections: SectionDescriptor[]
}

export const SectionFrame = ({ sections }: SectionFrameProps) => {
    const children = useMemo(() => {
        const sectionElements = sections.map(({ title, openByDefault, content }, idx) => (
            <Section key={`section-${idx}`} title={title} openByDefault={openByDefault} children={content} />
        ))

        return insertBetweenElements(sectionElements, idx => <Separator key={`sep-${idx}`} />)
    }, [sections])

    return <MainFrame>{children}</MainFrame>
}

const useStyles = createUseStyles({
    sectionIcon: {
        fontSize: '0.7em',
    },
    sectionTitle: {
        color: '#000000',
        '&:hover': {
            textDecoration: 'none',
            color: colors.grey9,
        },
    },
})

type SectionProps = {
    title?: string
    openByDefault?: boolean
}

export const Section: FC<SectionProps> = ({ title, children, openByDefault = true }) => {
    const hasTitle = title !== undefined
    const styles = useStyles()
    const [show, setShow] = useState(openByDefault || !hasTitle)

    const toggle = useCallback(() => {
        setShow(prev => !prev)
    }, [setShow])

    const iconName = show ? 'ChevronDown' : 'ChevronRight'

    return (
        <div>
            {hasTitle ? (
                <h3>
                    <Link className={styles.sectionTitle} onClick={toggle}>
                        <Icon className={styles.sectionIcon} iconName={iconName} /> {title}
                    </Link>
                </h3>
            ) : null}
            {show ? children : null}
        </div>
    )
}

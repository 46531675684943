import { createUseStyles } from 'react-jss'

import { colors } from '@publica/ui-common-styles'
import { FC } from '@publica/ui-common-utils'

const useStyles = createUseStyles({
    header: {
        padding: [[20, 0, 5, 0]],
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        backgroundColor: colors.blue1,
        color: '#FFF',
    },
    main: {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'nowrap',
        alignItems: 'center',
        flex: '1 0 0',
        padding: [[10, 20]],
    },
})

type TwoToneProps = {
    title: string
}

export const TwoTone: FC<TwoToneProps> = ({ children, title }) => {
    const styles = useStyles()

    return (
        <div className="ms-welcome">
            <section className={styles.header}>
                <Nexdo />
                <h1 className="ms-fontSize-su ms-fontWeight-light">{title}</h1>
            </section>
            <main className={styles.main}>{children}</main>
        </div>
    )
}

const useNexdoStyles = createUseStyles({
    name: {
        fontSize: '48px',
        marginTop: 30,
    },
    x: {
        color: colors.nexdoOrange,
    },
})

const Nexdo: FC = () => {
    const styles = useNexdoStyles()

    return (
        <div className={styles.name}>
            <span>Ne</span>
            <span className={styles.x}>x</span>
            <span>do</span>
        </div>
    )
}

import { createUseStyles } from 'react-jss'

import { ErrorFallbackProps } from '@publica/ui-common-components'
import { FC } from '@publica/ui-common-utils'
import { getErrorMessage } from '@publica/ui-common-utils'

import { TwoTone } from '../TwoTone'

const useStyles = createUseStyles({
    error: {
        marginTop: 30,
        textAlign: 'center',
    },
    errorTitle: {
        marginBottom: 20,
        fontSize: 'large',
    },
})

export const ErrorPane: FC<ErrorFallbackProps> = ({ error }) => {
    const styles = useStyles()
    const { title, message } = getErrorMessage(error, `Erreur`, `Une erreur s'est produite.`)

    return (
        <TwoTone title="Erreur">
            <div className={styles.error}>
                <div className={styles.errorTitle}>{title}</div>
                <p>{message}</p>
                <p>Veuillez fermer l'onglet et réessayer ultérieurement.</p>
            </div>
        </TwoTone>
    )
}

import { createUseStyles } from 'react-jss'

import { colors } from '@publica/ui-common-styles'
import { FC } from '@publica/ui-common-utils'

const version = `v${__VERSION__}`

const useStyles = createUseStyles({
    main: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        flexWrap: 'nowrap',
        padding: [[0, 20]],
        height: '100%',
    },
    version: {
        padding: 5,
        textAlign: 'center',
        bottom: 0,
        color: colors.grey6,
    },
})

export const MainFrame: FC = ({ children }) => {
    const styles = useStyles()

    return (
        <main className={styles.main}>
            <div>{children}</div>
            <div className={styles.version}>{version}</div>
        </main>
    )
}
